import breeam1 from '../../../static/images/icons/breeam.png'
import breeam2 from '../../../static/images/icons/breeam.png'
import gear from '../../../static/images/icons/gear.png'
import platinum from '../../../static/images/icons/leed_platinum.png'
import gold from '../../../static/images/icons/leed_gold.png'
import silver from '../../../static/images/icons/leed_silver.png'
import sanchoAvila from "../../../static/images/barcelona/sancho-de-avila-110-130.jpg"
import diagonal197 from "../../../static/images/barcelona/diagonal-197.jpg"
import torreMarenostrum from "../../../static/images/barcelona/torre-marenostrum.jpg"
import santCugat from "../../../static/images/barcelona/sant-cugat-nord.jpg"
import pgTillers from "../../../static/images/barcelona/pg-dels-tillers-2-6.jpg"
import diagonal682 from "../../../static/images/barcelona/diagonal-682.jpg"
import diagonal609 from "../../../static/images/barcelona/diagonal-609-615.jpg"
import diagonal525 from "../../../static/images/barcelona/diagonal-525.jpg"
import tga from "../../../static/images/barcelona/TGA.jpg"
import travesseraGracia from "../../../static/images/barcelona/travessera-de-gracia-47-49.jpg"
import diagonal532 from "../../../static/images/barcelona/diagonal-532.jpg"
import viaAugusta from "../../../static/images/barcelona/via-augusta-21.jpg"
import diagonal409 from "../../../static/images/barcelona/diagonal-409.jpg"
import gallaPlacidia from "../../../static/images/barcelona/galla-placidia.jpg"
import ciutatGranada from "../../../static/images/barcelona/ciutat-granada-150.jpg"
import diagonalGlories from "../../../static/images/barcelona/diagonal-glories.jpg"
import Illacuna from "../../../static/images/barcelona/Illacuna.jpg"
import wittywood from "../../../static/images/barcelona/wittywood.jpg"
import plazaEuropa from "../../../static/images/barcelona/plaza-europa.jpg"
import torreBcn from "../../../static/images/barcelona/torre-bcn.jpg"

const barcelonaData= [
    {
        id: "42",
        name: "Sancho de Ávila",
        address: "Sancho de Ávila, 110-130",
        img: sanchoAvila,
        img_path: "barcelona/sancho-de-avila-110-130.jpg",
        new: "Nuevo Proyecto",
        active: 'Sancho de Ávila',
        bream1: "",
        bream2: "",
        bream3: "",
        hidden: "invisible",
        hidden2: "invisible",
        hidden3: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: platinum,
        position: { lat: 41.401151703028155, lng: 2.1935859642540194 },
          bimCoordination: "#",
          bimArchitecture: "https://docs.b360.autodesk.com/projects/a89c9298-a610-4762-9b99-1be3605e9811/folders/urn:adsk.wipprod:fs.folder:co.4lYJUi4hS4Cy66_RykOUwQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:t-KhJpvtRU65mwhvU7_fGw",
          bimInstallations: "#",
          bimMepMechanics: 'https://docs.b360.autodesk.com/projects/a89c9298-a610-4762-9b99-1be3605e9811/folders/urn:adsk.wipprod:fs.folder:co.Cep48jDmRECDK1x-m9no9Q/detail/viewer/items/urn:adsk.wipprod:dm.lineage:LCt9J0LiSJaHzRoTDJnh_w',
          bimMepPlumbing: 'https://docs.b360.autodesk.com/projects/a89c9298-a610-4762-9b99-1be3605e9811/folders/urn:adsk.wipprod:fs.folder:co.Cep48jDmRECDK1x-m9no9Q/detail/viewer/items/urn:adsk.wipprod:dm.lineage:4sWaOX9IRICzM_gvHkQUBw',
          bimMepElectricity: 'https://docs.b360.autodesk.com/projects/a89c9298-a610-4762-9b99-1be3605e9811/folders/urn:adsk.wipprod:fs.folder:co.Cep48jDmRECDK1x-m9no9Q/detail/viewer/items/urn:adsk.wipprod:dm.lineage:zSdfVXxGR-m62OXypFHWDA',
          bimMepPci: 'https://docs.b360.autodesk.com/projects/a89c9298-a610-4762-9b99-1be3605e9811/folders/urn:adsk.wipprod:fs.folder:co.Cep48jDmRECDK1x-m9no9Q/detail/viewer/items/urn:adsk.wipprod:dm.lineage:zMz8LBspQhKB2WGmrYZ1Xw',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: 'https://docs.b360.autodesk.com/projects/a89c9298-a610-4762-9b99-1be3605e9811/folders/urn:adsk.wipprod:fs.folder:co.x7YKSPQ2SYuhjvXcyot2WA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:-_ebvmnEQ7e-Ct9m9wAGwA',
          bimStructures: 'https://docs.b360.autodesk.com/projects/a89c9298-a610-4762-9b99-1be3605e9811/folders/urn:adsk.wipprod:fs.folder:co.CSn_-ShKSeydFOH_-kiMgw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Zp03rLCdRZCvF-olEvGxfA',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "43",
        name: "Diagonal 197",
        address: "Diagonal, 197",
        img: diagonal197,
        img_path: "barcelona/diagonal-197.jpg",
        new: "Nuevo Proyecto",
        active: 'Diagonal 197',
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte I: Very Good",
        bream3: "Leed Gold",
        hidden: "",
        hidden2: "",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 41.404119047853925, lng: 2.191564119558903 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/6f96781e-fdcd-4482-b39e-1047521790e4/folders/urn:adsk.wipprod:fs.folder:co.RdfPYPkVSiSf8yIe7UCAKQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:VEbM5DOoSCWf-jlcnBdUzA',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: 'https://docs.b360.autodesk.com/projects/6f96781e-fdcd-4482-b39e-1047521790e4/folders/urn:adsk.wipprod:fs.folder:co.TRMchMd0S0CftbeVxWBD2w/detail/viewer/items/urn:adsk.wipprod:dm.lineage:gF9HFsh6SGWrxq6vG5cOfw',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "44",
        name: "Torre Marenostrum",
        address: "Plaça del Gas, 1",
        img: torreMarenostrum,
        img_path: "barcelona/torre-marenostrum.jpg",
        bream1: "",
        bream2: "",
        bream3: "",
        hidden: "invisible",
        hidden2: "invisible",
        hidden3: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: platinum,
        position: { lat: 41.383363306425615, lng: 2.190658862327934 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "45",
        name: "Sant Cugat Nord",
        address: "Plaça Xavier Cugat, 2",
        img: santCugat,
        img_path: "barcelona/sant-cugat-nord.jpg",
        bream1: "Bream Parte I: Excellent",
        bream2: "Bream Parte II: Outstanding",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: platinum,
        position: { lat: 41.49316438442929, lng: 2.0752121506160655 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/bfbb4f5a-d14a-483f-8d91-5d0205b6442e/folders/urn:adsk.wipprod:fs.folder:co.k6sm1OVXRQaNmX6_H6V5DQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:GjMKG_FCSQ2xUk0A7kt84w',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "46",
        name: "Pg. dels Til.lers 2-6",
        address: "Pg. dels Til.lers, 2-6",
        img: pgTillers,
        img_path: "barcelona/pg-dels-tillers-2-6.jpg",
        bream1: "Leed Gold",
        bream2: "",
        bream3: "",
        hidden: "invisible",
        hidden2: "invisible",
        imgbream1: gold,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 41.389874132336594, lng: 2.118437221436243 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "47",
        name: "Diagonal 682",
        address: "Diagonal, 682",
        img: diagonal682,
        img_path: "barcelona/diagonal-682.jpg",
        bream1: "Bream Parte I: Very Good",
        bream2: "Bream Parte II: Outstanding",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 41.38831809225034, lng: 2.123067106207425 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "48",
        name: "Diagonal 609-615",
        address: "Diagonal, 609-615",
        img: diagonal609,
        img_path: "barcelona/diagonal-609-615.jpg",
        bream1: "Bream Parte I: Very Good",
        bream2: "Bream Parte II: Excellent",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 41.38826164051448, lng: 2.128901379795584 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/722775da-f6f4-44db-8b22-2966de4c157d/folders/urn:adsk.wipprod:fs.folder:co.Q0FQxt0LSGqDhHmH6pht9w/detail/viewer/items/urn:adsk.wipprod:dm.lineage:cFfzdmsIQkaR8JPe22pFiA',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "49",
        name: "Diagonal, 525",
        address: "Diagonal, 525",
        img: diagonal525,
        img_path: "barcelona/diagonal-525.jpg",
        bream1: "Certificado en trámite",
        bream2: "",
        bream3: "",
        hidden: "invisible",
        hidden2: "invisible",
        imgbream1: gear,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 41.39098753486637, lng: 2.1390556214683105 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "50",
        name: "TGA",
        address: "Travessera de Gràcia, 11",
        img: tga,
        img_path: "barcelona/tga.jpg",
        bream1: "Bream Parte I: Very Good",
        bream2: "Bream Parte II: Outstanding",
        bream3: "Leed Gold",
        hidden: "",
        hidden2: "",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 41.39415260175718, lng: 2.146030783961013 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/96d42b21-d00e-4beb-8194-2f187ac9e06e/folders/urn:adsk.wipprod:fs.folder:co.2p3K34mNRQOkzXxgQZxOLg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:_i3m0X1AR2SyiPP3c0Vh4A',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "51",
        name: "T. Gràcia",
        address: "T. de Gràcia, 47-49",
        img: travesseraGracia,
        img_path: "barcelona/travessera-de-gracia-47-49.jpg",
        bream1: "Bream Parte I: Very Good",
        bream2: "Bream Parte II: Outstanding",
        bream3: "Leed Gold",
        hidden: "",
        hidden2: "",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 41.395893264197476, lng: 2.1486417072975392 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/e0cd2cef-fbf8-4db2-a739-fe11c7e6d381/folders/urn:adsk.wipprod:fs.folder:co.fFhk0p46QmqkRHN0xeB-7g/detail/viewer/items/urn:adsk.wipprod:dm.lineage:BHd_VrLbTS6IqYUnwFrAYg',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
    {
      id: "52",
      name: "Diagonal 530",
      address: "Diagonal, 530",
      img: diagonal532,
      img_path: "barcelona/diagonal-532.jpg",
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 41.39512312154511, lng: 2.1521952775962183 },
          bimCoordination: 'https://docs.b360.autodesk.com/projects/53fa0018-9eb7-4020-a847-9f2c89993c20/folders/urn:adsk.wipprod:fs.folder:co.Y6bjgnF6RK2gjo1Amo54VQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:NRXk73sATKau4wzdF9IltA',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/53fa0018-9eb7-4020-a847-9f2c89993c20/folders/urn:adsk.wipprod:fs.folder:co.VCKeNFdAQGKxovWO14sUXQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:4Z_-TnVhSS2HYGT-Ukz2RQ',
          bimInstallations: 'https://docs.b360.autodesk.com/projects/53fa0018-9eb7-4020-a847-9f2c89993c20/folders/urn:adsk.wipprod:fs.folder:co.QJuERtdkSai1jCzQndVraA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:pHNbjjfvSGerNYI3-AgPMg',
          bimMepMechanics: 'https://docs.b360.autodesk.com/projects/53fa0018-9eb7-4020-a847-9f2c89993c20/folders/urn:adsk.wipprod:fs.folder:co.QJuERtdkSai1jCzQndVraA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:pHNbjjfvSGerNYI3-AgPMg',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: 'https://docs.b360.autodesk.com/projects/53fa0018-9eb7-4020-a847-9f2c89993c20/folders/urn:adsk.wipprod:fs.folder:co.1Me3dYw5SWeNh7YV4vOsQw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:c574z1cJSCG-Uh1D4vGJQQ',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "https://docs.b360.autodesk.com/projects/53fa0018-9eb7-4020-a847-9f2c89993c20/folders/urn:adsk.wipprod:fs.folder:co.hyTE4aEZQYyS8FV3idE44Q/detail/viewer/items/urn:adsk.wipprod:dm.lineage:M2OgBT5HT6yrrq3GYGrK6Q",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "https://aec.cintoo.com/41637192E2626360F193",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "53",
      name: "Via Augusta 21",
      address: "Via Augusta, 21",
      img: viaAugusta,
      img_path: "barcelona/via-augusta-21.jpg",
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 41.397207129483384, lng: 2.1544468043037917 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/9679eadb-0a2c-484f-8530-dff2b286ead0/folders/urn:adsk.wipprod:fs.folder:co.adW1k0aLRvGZiyG8S6vCwQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Rpnm09CgQbmzi5GP-pcqUQ',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "54",
      name: "Diagonal 409",
      address: "Diagonal, 409",
      img: diagonal409,
      img_path: "barcelona/diagonal-409.jpg",
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "Leed Gold",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 41.39518677207788, lng: 2.1547451928617556 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/9b73e764-70ff-4408-ad34-6ae1f0299690/folders/urn:adsk.wipprod:fs.folder:co.mREMHvvSRz2uuqW1pPk5Dg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:ReW2U8XkR4mAZ1-IcSZn-A',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "55",
      name: "Gal·la Placídia",
      address: "P. Gal·la Placídia, 1, 3",
      img: gallaPlacidia,
      img_path: "barcelona/galla-placidia.jpg",
      bream1: "",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      hidden3: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: silver,
      position: { lat: 41.39982616308666, lng: 2.1518665923096982 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "56",
      name: "Ciutat Granada",
      address: "Ciutat de Granada, 150",
      img: ciutatGranada,
      img_path: "arcelona/ciutat-granada-150.jpg",
      bream1: "Leed Platinum",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      imgbream1: platinum,
      imgbream2: breeam2,
      imgbream3: silver,
      position: { lat: 41.403286244358846, lng: 2.192298235641076 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/e71aba1e-1d83-4208-b524-154b28a9560a/folders/urn:adsk.wipprod:fs.folder:co.tLMo5l5GS2mOvt5eTbMTSw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:YaomL3iIQZevXlNk8LXyqg',
          bimInstallations: 'https://docs.b360.autodesk.com/projects/e71aba1e-1d83-4208-b524-154b28a9560a/folders/urn:adsk.wipprod:fs.folder:co.VOyaV7SyQtGZzhSE8uskpg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:aVZBgHtESHSIGUHiFp-BTg',
          bimMepMechanics: 'https://docs.b360.autodesk.com/projects/e71aba1e-1d83-4208-b524-154b28a9560a/folders/urn:adsk.wipprod:fs.folder:co.VOyaV7SyQtGZzhSE8uskpg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:aVZBgHtESHSIGUHiFp-BTg',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: 'https://docs.b360.autodesk.com/projects/e71aba1e-1d83-4208-b524-154b28a9560a/folders/urn:adsk.wipprod:fs.folder:co.4hN2Kum0QgW2A7CmOaOGXw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:XRx8-2t9TNy04SoK0Rza0Q',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "57",
      name: "D. Glòries",
      address: "Diagonal, 220 - 240",
      img: diagonalGlories,
      img_path: "barcelona/diagonal-glories.jpg",
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 41.40491319284268, lng: 2.191412319826674 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "58",
      name: "Illacuna",
      address: "Carrer de la Llacuna, 56-70",
      img: Illacuna,
      img_path: "barcelona/Illacuna.jpg",
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Outstanding",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 41.401521684060086, lng: 2.1988927797673394 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "59",
      name: "Wittywood",
      address: "Llacuna, 42, Barcelona",
      img: wittywood,
      img_path: "barcelona/wittywood.jpg",
      bream1: "Certificado en trámite",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      imgbream1: gear,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 41.40047635243087, lng: 2.2002345223023587 },
          bimCoordination: 'https://docs.b360.autodesk.com/projects/4d211b87-388a-4a2d-ac5d-a00d0d70d035/folders/urn:adsk.wipprod:fs.folder:co.FmzozXVCQzao_aNdtNaDTA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:YGm6bXUnRkmYcKNYLAUv7A',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/4d211b87-388a-4a2d-ac5d-a00d0d70d035/folders/urn:adsk.wipprod:fs.folder:co.M_UZBpHhTha-PAfb2LvDHA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:5cZOuaM-TN-c733Rc1ZYWA',
          bimInstallations: 'https://docs.b360.autodesk.com/projects/4d211b87-388a-4a2d-ac5d-a00d0d70d035/folders/urn:adsk.wipprod:fs.folder:co.oO3gahWmSbWylcICEhmIpw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:lDeWem4nRfW8XAPow62xvA',
          bimMepMechanics: 'https://docs.b360.autodesk.com/projects/4d211b87-388a-4a2d-ac5d-a00d0d70d035/folders/urn:adsk.wipprod:fs.folder:co.oO3gahWmSbWylcICEhmIpw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:lDeWem4nRfW8XAPow62xvA',
          bimMepPlumbing: 'https://docs.b360.autodesk.com/projects/4d211b87-388a-4a2d-ac5d-a00d0d70d035/folders/urn:adsk.wipprod:fs.folder:co.oO3gahWmSbWylcICEhmIpw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:A3JZyyQySgiIVyndYgxF7w',
          bimMepElectricity: 'https://docs.b360.autodesk.com/projects/4d211b87-388a-4a2d-ac5d-a00d0d70d035/folders/urn:adsk.wipprod:fs.folder:co.oO3gahWmSbWylcICEhmIpw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Sky5LzD4QtuBArOd-7y-7Q',
          bimMepPci: 'https://docs.b360.autodesk.com/projects/4d211b87-388a-4a2d-ac5d-a00d0d70d035/folders/urn:adsk.wipprod:fs.folder:co.oO3gahWmSbWylcICEhmIpw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:3mlTqHf8R8mbOKVNqx02Aw',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "https://aec.cintoo.com/C393B3E3604270925192",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "60",
      name: "Plaza Europa",
      address: "Plaza Europa, 34",
      img: plazaEuropa,
      img_path: "barcelona/plaza-europa.jpg",
      bream1: "",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      hidden3: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 41.358137124302075, lng: 2.1228229214640146 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },
    {
      id: "61",
      name: "Torre BCN",
      address: "V. Corts Catalanes, 130",
      img: torreBcn,
      img_path: "barcelona/torre-bcn.jpg",
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte I: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 41.36398556172812, lng: 2.13513127732116 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/368b226d-95da-4803-a001-66d9f45841b6/folders/urn:adsk.wipprod:fs.folder:co.kZPmMm3pQomzzfdimPb7sA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:65qfRiOUQom5BdjJwfe3Vg',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          bimStructures: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
    },    
]
export default barcelonaData
